<template>
  <Window
    :modal="true"
    class="modal"
    @close="onClose"
  >
    <template #header>
      <span v-t="'ImagePickerWindow.ImagePickerModal.header'" />
    </template>

    <template #body>
      <div class="container">
        <div class="infos">
          <div class="button-wrapper">
            <span
              class="button base-button button"
              @click="$refs.file.click()"
            >
              <input
                ref="file"
                type="file"
                accept="image/*"
                @change="loadImage($event)"
              >
              Sélectionner une image
            </span>
          </div>
          <preview
            :width="dimensions.width"
            :height="dimensions.height"
            :image="result.image"
            :coordinates="result.coordinates"
            class="my-preview"
          />
        </div>
        <div class="content">
          <cropper
            ref="cropper"
            class="cropper"
            :src="image"
            :stencil-props="{
              aspectRatio: dimensions.ratio
            }"
            :debounce="false"
            :min-height="50"
            :min-width="50"
            @change="onChange"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <Button
        :label="$t('ImagePickerWindow.ImagePickerModal.saveButtonLabel')"
        class="dark"
        @click="onConfirm"
      />
    </template>
  </Window>
</template>

<script>
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  name: 'ImagePickerModal',
  components: {
    Cropper,
    Preview
  },
  data () {
    return {
      result: {
        coordinates: null,
        image: null
      },
      image: null
    }
  },
  computed: {
    initializerPictureURL () {
      return this.$store.state.modals.imagePickerModal.initializerPictureURL
    },
    dimensions () {
      return this.$store.state.modals.imagePickerModal.dimensions || { ratio: 1 / 1, width: 100, height: 100 }
    }
  },
  mounted () {
    if (this.initializerPictureURL !== undefined) {
      this.image = this.initializerPictureURL
    }
  },
  methods: {
    reset () {
      this.image = null
    },
    loadImage (event) {
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.image = e.target.result
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },
    onChange ({ coordinates, image }) {
      this.result = {
        coordinates,
        image
      }
    },
    onConfirm () {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        canvas.toBlob(blob => {
          this.fileName = 't.png'
          this.$store.state.modals.imagePickerModal.onConfirm(blob, this.fileName)
          this.onClose()
          // Perhaps you should add the setting appropriate file format here
        }, 'image/jpeg')
      }
    },
    onClose () {
      this.$store.dispatch('modals/closeImagePickerModal')
    }
  }
}
</script>

<style lang="scss" scoped>

.modal {
  display: flex;
}

.container {
  .infos {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1rem;
  }

  .my-preview {
    width: 100px;
    height: 100px;
    border: solid 1px #EEE;
    border-radius: 6px;
    overflow: hidden;
  }
}

.cropper {
  border: solid 1px #EEE;
  height: 300px;
  width: 520px;
}

.button input {
  display: none;
}

</style>
